import { VipProps } from './index';
import { useSearchParams } from 'react-router-dom';
import { TextEditorProps } from '../text-editor/TextEditor';
import { ReduxSelectorProps } from '../../services/constants';
import './style.scss';

export const Vip = ({
  useAppSelector,
  ...vipData
}: VipProps & TextEditorProps & ReduxSelectorProps) => {
  const cartData = useAppSelector(state => state.cart?.cartData);
  const [searchParams] = useSearchParams();
  return (
    <>
      {(searchParams.get('cart') == 'PHOENIX_CART_PREVIEW' ||
        cartData?.HasSubscription) && (
        <div className="pt-2 border-t border-gray-200 vip-block">
          <div className="border border-black rounded-lg px-6 py-10 sm:py-5 lg:px-8 bg-white">
            <div className="mx-auto max-w-2xl text-center mb-6">
              <h2
                className={`flex gap-0.5 justify-center items-end font-bold rounded tracking-tight ${vipData?.Title?.fontWeight}`}
                style={{
                  fontSize: `${vipData?.Title?.fontSize}px`,
                  color: vipData?.Title?.textColor,
                }}>
                {vipData?.Title?.text}
                <span className="text-l relative top-[-3px]">&reg;</span>
              </h2>
              <p
                className={`mt-4 font-bold text-left ${vipData?.Description?.fontWeight}`}
                style={{
                  fontSize: `${vipData?.Description?.fontSize}px`,
                  color: vipData?.Description?.textColor,
                }}>
                {vipData?.Description?.text}
              </p>
              <div
                className={`mt-6 text-left leading-5 ${vipData?.BenefitTitle?.fontWeight}`}>
                <span
                  style={{
                    fontSize: `${vipData?.BenefitTitle?.fontSize}px`,
                    color: vipData?.BenefitTitle?.textColor,
                  }}>
                  {vipData?.BenefitTitle?.text}:
                </span>
                {vipData?.BenefitDescription?.text
                  ?.split(',')
                  ?.map((benefit, index) => (
                    <p
                      key={index}
                      className={`mb-4 ${vipData?.BenefitDescription?.fontWeight}`}
                      style={{
                        fontSize: `${vipData?.BenefitDescription?.fontSize}px`,
                        color: vipData?.BenefitDescription?.textColor,
                      }}>
                      {benefit}
                    </p>
                  ))}
              </div>
              <p className="mt-4  text-left leading-5 text-black">
                <div
                  className="quill-text"
                  dangerouslySetInnerHTML={{ __html: vipData?.content }}
                />
              </p>
            </div>
          </div>
        </div>
      )}
      <>
        <br />
        <div className="text-base border-t border-gray-200 ">
          <br />
          <dd className="text-sm font-medium">
            This offer is exclusively for paying members. Any orders from
            non-paying customers will be refunded and not fulfilled.
          </dd>
        </div>
      </>
    </>
  );
};
