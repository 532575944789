import React, { useEffect, useMemo, useState } from 'react';
import StatsCards from './StatsCards';
import './style.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import CustomDatePicker from 'components/common/customDatePicker';
// import ToggleSwitch from 'components/common/toggleSwitch';
import {
  IDashBoardBody,
  LifetimeStats,
  LifetimeSubscriptionStats,
  LifeTimetransactionStats,
  UniqueAprrovalStats,
} from 'interface/dashboardNewIterface';
import { useAppSelector } from 'hooks/reduxHooks';
import {
  getLifeTimeStats,
  getAggregatedTransactions,
  getUniqueApprovals,
  getLifeTimeSubscriptions,
} from 'services/dashboardNewService';
import { groupBy } from 'lodash';
import { groupPoints } from 'utils/helper';
import SalesRevenue from './SaleRevenue';
import {
  ISubscriptionStats,
  ITransactionStats,
} from 'interface/dashboardInterface';
import {
  getSubscriptionStats,
  getTransactionStats,
  getUniqueTransactionStats,
} from 'services/dashboardService';
import NetSubscribers from './NetSubscribers';
import UniqueApprovalRatio from './UniqueApprovalRatio';
import CardBreakDown from './CardBreakDown';
import TransactionStatus from './TransactionStatus';
import CoverageHealth from './CoverageHealth';
import ChargeBackSummary from './ChargeBackSummary';
import { Loader } from 'components/common/loader';

export interface IDashboardFilter {
  start_time: Dayjs;
  end_time: Dayjs;
  date_range: string;
  toggle: boolean;
  start_month: Dayjs;
  end_month: Dayjs;
}

const emptyStats = {
  transaction: {
    Amount: 0,
    RefundedAmount: 0,
    Count: 0,
    SourceCount: 0,
    ChargeType: '',
    CardBrand: '',
    Status: '',
    DisputeAlerts: [],
    CycleNumber: 0,
    Date: '',
  },
  lifetimeSubscriptionStats: {
    Approved: 0,
    Date: '',
    Kind: '',
    Losses: 0,
    Revenue: 0,
  },
  subscription: {
    NewSubscriptions: 0,
    CanceledSubscriptions: 0,
    SalvagingSubscriptions: 0,
    RecoveredSubscriptions: 0,
    RenewedSubscriptions: 0,
    Date: '',
  },
};

export default function Dashboard2() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [transactionDisputesCodeData, setTransactionDisputesCodeData] =
    useState<ITransactionStats | null>(null);
  const [lifetimeSubscriptionStats, setLifeTimeSubscriptionStats] =
    useState<LifetimeSubscriptionStats | null>(null);
  const [subscriptionStats, setSubscriptionStats] =
    useState<ISubscriptionStats | null>(null);
  const [lifeTimeStats, setLifeTimeStats] = useState<LifetimeStats[] | null>(
    null,
  );
  const [aggregatedTransactionStats, setAggregatedTransactionStats] = useState<
    LifeTimetransactionStats[]
  >([]);
  const [uniqueApprovalStats, setUniqueApprovalStats] = useState<
    UniqueAprrovalStats[]
  >([]);
  const default_start_time = dayjs().tz().startOf('day');
  const default_end_time = dayjs().tz().endOf('day');
  const [filter, setFilter] = useState<IDashboardFilter>({
    start_time: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : default_start_time,
    end_time: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : default_end_time,
    date_range: searchParams.get('date_range') || 'Today',
    end_month: dayjs().tz().endOf('month'),
    start_month: dayjs().tz().startOf('month'),
    toggle: Boolean(searchParams.get('toggle')) || false,
  });
  const storeIds = useAppSelector(state => state.storeIds.storeIds);
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const handleFilter = (
    filterProptery: keyof typeof filter,
    value: unknown,
  ) => {
    setFilter(pre => ({ ...pre, [filterProptery]: value }));
  };

  const groupedTransactionStats = useMemo(() => {
    if (
      !lifetimeSubscriptionStats ||
      !lifetimeSubscriptionStats?.Data?.length
    ) {
      return [];
    }
    const grouped = groupBy(lifetimeSubscriptionStats.Data, 'Date');
    return groupPoints(
      grouped,
      emptyStats?.lifetimeSubscriptionStats,
      true,
      lifetimeSubscriptionStats?.BinWidth,
    );
  }, [lifetimeSubscriptionStats]);

  const groupedTransactionStatsBreak = useMemo(() => {
    if (
      !transactionDisputesCodeData ||
      !transactionDisputesCodeData?.Stats?.length
    ) {
      return [];
    }
    const grouped = groupBy(transactionDisputesCodeData.Stats, 'Date');
    return groupPoints(grouped, emptyStats.transaction);
  }, [transactionDisputesCodeData]);

  const getDashboardstats = async () => {
    const payload: IDashBoardBody = {
      EndTime: filter.end_time.add(1, 'day').startOf('day').tz().format(),
      StartTime: filter.start_time.tz().format(),
      StoreIDs: storeIds.length ? storeIds.map(val => val.ID) : null,
    };
    setLoading(true);
    const response = await Promise.allSettled([
      getSubscriptionStats(payload),
      getLifeTimeStats({ StoreIDs: payload.StoreIDs }),
      getLifeTimeSubscriptions(payload),
      getAggregatedTransactions(payload),
      getUniqueApprovals(payload),
      getTransactionStats(payload),
      getUniqueTransactionStats(payload),
    ]);
    setLoading(false);
    if (response?.[0]?.status === 'fulfilled' && response?.[0]?.value?.data) {
      setSubscriptionStats(response?.[0]?.value?.data);
    } else setSubscriptionStats(null);
    if (response?.[1]?.status === 'fulfilled' && response?.[1]?.value?.data) {
      setLifeTimeStats(response?.[1]?.value?.data?.Result);
    } else setLifeTimeStats(null);
    if (response?.[2]?.status === 'fulfilled' && response?.[2]?.value?.data) {
      setLifeTimeSubscriptionStats(response?.[2]?.value?.data);
    } else setLifeTimeSubscriptionStats(null);
    if (response?.[3]?.status === 'fulfilled' && response?.[3]?.value?.data) {
      setAggregatedTransactionStats(response?.[3]?.value?.data?.Result);
    } else setAggregatedTransactionStats([]);
    if (response?.[4]?.status === 'fulfilled' && response?.[4]?.value?.data) {
      setUniqueApprovalStats(response?.[4]?.value?.data?.Result);
    } else setUniqueApprovalStats([]);
    if (response?.[5]?.status === 'fulfilled' && response?.[5]?.value?.data) {
      setTransactionDisputesCodeData(response?.[5]?.value?.data);
    } else setTransactionDisputesCodeData(null);
  };

  const groupedSubscriptionStats = useMemo(() => {
    if (!subscriptionStats || !subscriptionStats?.Stats?.length) {
      return [];
    }
    const grouped = groupBy(subscriptionStats.Stats, 'Date');
    return groupPoints(
      grouped,
      emptyStats.subscription,
      true,
      subscriptionStats.BinWidth,
    );
  }, [subscriptionStats]);

  useEffect(() => {
    getDashboardstats();
  }, [storeIds, filter.end_time, filter.start_time, timeZone]);

  return (
    <div className="new_dashboard">
      <div className="dashboard_filter row-full">
        <div className="dual-date-container">
          <div className="common_label_text">Date Range</div>
          <CustomDatePicker
            selectedRange={{
              startDate: filter?.start_time,
              endDate: filter?.end_time,
            }}
            dateValues={range => {
              if (range.startDate && range.endDate) {
                handleFilter('start_time', range?.startDate);
                handleFilter('end_time', range?.endDate);
              }
            }}
            maxDate={dayjs().tz().toDate()}
          />
        </div>
      </div>

      <StatsCards
        uniqueTransactionStats={uniqueApprovalStats}
        lifeTimeStats={lifeTimeStats}
        subscriptionStats={subscriptionStats}
        transactionStats={lifetimeSubscriptionStats?.Data || null}
        filter={filter}
      />
      {filter.toggle ? (
        <div></div>
      ) : (
        <div className="line_chart_container">
          <div className="grid grid-cols-2 gap-2">
            {' '}
            <SalesRevenue groupedTransactionStats={groupedTransactionStats} />
            <NetSubscribers
              groupedSubscriptionStats={groupedSubscriptionStats}
            />
            <UniqueApprovalRatio uniqueApprovalStats={uniqueApprovalStats} />
            <ChargeBackSummary
              groupedTransactionStats={groupedTransactionStatsBreak}
            />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <TransactionStatus
              aggregatedTransactionStats={aggregatedTransactionStats}
            />
            <CardBreakDown cardBreakDownStats={aggregatedTransactionStats} />
            <CoverageHealth
              transactionDisputesCode={
                transactionDisputesCodeData?.Stats
                  ? transactionDisputesCodeData?.Stats
                  : []
              }
            />
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </div>
  );
}
