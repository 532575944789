import { TextField } from '@mui/material';
import { IBlackListIP } from 'interface/storeInterface';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { PopUpModal } from 'shared-components';

interface IProps {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  getIsBlacklisted: boolean;
  IPform: UseFormReturn<IBlackListIP, undefined>;
  handleConform: (_: IBlackListIP) => void;
}

const IpPopup: FC<IProps> = ({
  open,
  handleClose,
  loading,
  getIsBlacklisted,
  IPform,
  handleConform,
}) => {
  const { control, handleSubmit } = IPform;
  return (
    <PopUpModal
      open={open}
      handleClose={() => handleClose()}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'NO',
          buttonFunction: () => {
            handleClose();
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: 'YES',
          type: 'submit',
          form: 'ipForm',
          loading: loading,
        },
      ]}
      heading={
        getIsBlacklisted ? 'Unblock this IP?' : 'Are you sure to Block this IP?'
      }
      className="flex-container">
      <div className="popup-section black_list_popup">
        <form
          className="grid gap-4"
          id="ipForm"
          onSubmit={handleSubmit(handleConform)}>
          {!getIsBlacklisted ? (
            <Controller
              name="Description"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { ref, value, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    label="Description"
                    className="filter_form_input"
                    placeholder="Enter the description"
                    ref={ref}
                  />
                );
              }}
            />
          ) : (
            <div className="popup-section !w-full">
              <p className="popup-question">{`Are you sure to Unblock this IP ${IPform.getValues('IpAddress')}`}</p>
            </div>
          )}
        </form>
      </div>
    </PopUpModal>
  );
};

export default IpPopup;
