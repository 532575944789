import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { PaymentController } from './PaymentController';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import TextCustomInput from '../../puck/reusable-props/TextCustomInput';
import { CustomizeProps } from '../../interface/commonInterface';

export type PaymentProps = {
  subText: CustomizeProps;
  isShowBillingAddress?: boolean;
  showCardName?: boolean;
} & MarginProps;

export const Payment = ({
  useAppSelector,
  useAppDispatch,
}: ReduxSelectorProps & ReduxDispatchProps): ComponentConfig<PaymentProps> => {
  return {
    label: (
      <CustomTooltip title="Payment">
        <span>Payment</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      subText: {
        type: 'custom',
        label: 'Sub Text',
        render: ({ value, onChange }) => (
          <TextCustomInput
            value={value}
            onChange={onChange}
            title="Sub Text"
            showHover={false}
          />
        ),
      },
      ...marginFields,
      isShowBillingAddress: {
        type: 'radio',
        label: 'Show Billing Address',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      showCardName: {
        type: 'radio',
        label: 'Show Card Name',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    },
    defaultProps: {
      subText: {
        text: 'All transactions are secured and encrypted',
        fontSize: '12',
        textColor: '#000000',
        fontWeight: 'font-bold',
        hoverColor: '#000000',
      },
      isShowBillingAddress: true,
      showCardName: true,
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin, isShowBillingAddress, showCardName, subText }) => {
      return (
        <PaymentController
          fields={{ margin, isShowBillingAddress, showCardName, subText }}
          useAppSelector={useAppSelector}
          useAppDispatch={useAppDispatch}
        />
      );
    },
  };
};
