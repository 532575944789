import { AgreeAndContinueButton } from '../shared/buttons/AgreeAndContinueButton';
import { PayNowButton } from '../shared/buttons/PayNowButton';
import { ReturnButton } from '../shared/buttons/ReturnButton';
import { useFormContext } from 'react-hook-form';
import { ContactInformationFormType } from '../../interface/checkoutInterface';
import { setPurchaseCompleted } from '../../redux/slices/commonSlice';
import { ComponentConfig } from '@measured/puck';
import LoadingSpinner from '../shared/LoadingSpinner';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  IDropdownOptions,
  navigationPathField,
} from '../../../page-builder/puck/reusable-props/navigation-path-field';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../components/common/tooltip';

export type SingleStepButtonSectionProps = {
  nextPagePath: IDropdownOptions;
} & MarginProps;

export interface SingleStepButtonComponentProps
  extends ReduxSelectorProps,
    ReduxDispatchProps {
  baseService: AxiosInstance;
}

export const SingleStepButtonSection = ({
  useAppDispatch,
  useAppSelector,
  baseService,
}: SingleStepButtonComponentProps): ComponentConfig<SingleStepButtonSectionProps> => {
  return {
    label: (
      <CustomTooltip title="ConfirmPurchase">
        <span>Confirm Purchase</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...navigationPathField(baseService),
      ...marginFields,
    },
    defaultProps: {
      nextPagePath: {
        label: '',
        value: '',
      },
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-1',
          bottom: 'max-md:mb-1',
          right: 'max-md:mr-1',
          left: 'max-md:ml-1',
        },
        mobile: {
          top: 'max-sm:mt-1',
          bottom: 'max-sm:mb-1',
          right: 'max-sm:mr-1',
          left: 'max-sm:ml-1',
        },
      },
    },
    render: ({ nextPagePath, margin }) => {
      const navigate = useNavigate();
      const { pathname } = useLocation();
      const dispatch = useAppDispatch();
      const hasSubscription = useAppSelector(
        state => state.checkout.hasSubscription,
      );
      const {
        isTermsAndSubscriptionInPublishPage,
        isTermsAccepted: isTermsAndSubscription,
      } = useAppSelector(state => state.termsAndSub);
      const isTermsAccepted = useAppSelector(
        state => state.common.isTermsAccepted,
      );

      const { submitSuccess, purchaseCompleted } = useAppSelector(
        state => state.common,
      );
      const storeData = useAppSelector(state => state.store.storeData);
      const showVip = hasSubscription;
      const { formState } = useFormContext<ContactInformationFormType>();
      const isEmpty = (obj: object) =>
        obj ? Object.keys(obj).length === 0 : true;

      useEffect(() => {
        if (purchaseCompleted) {
          navigate(pathname + nextPagePath?.value);
          dispatch(setPurchaseCompleted(false));
        }
      }, [purchaseCompleted, isTermsAccepted]);
      return (
        <>
          <div
            className={`mt-10 sm:items-center sm:justify-between ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
            <div className="relative flex justify-center items-center">
              {isTermsAndSubscriptionInPublishPage &&
              !isTermsAndSubscription ? (
                <AgreeAndContinueButton showVip={showVip} />
              ) : (
                <PayNowButton showVip={showVip} />
              )}
              {submitSuccess && (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-90">
                  <div className="h-9">
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>
            <ReturnButton shopUrl={storeData?.ShopURL} />
          </div>
          {!isEmpty(formState?.errors) && (
            <div className="text-red-500 text-xs sm:text-right mt-1">
              * Please fix the errors mentioned above.
            </div>
          )}
        </>
      );
    },
  };
};
