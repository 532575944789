import { Autocomplete, Paper, TextField } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/Vector.svg';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import './style.scss';
import { RefObject, useEffect, useRef } from 'react';

interface IProp {
  label: string;
  value: string;
}
interface IAutoCompleteSelect<T> {
  options: T[];
  onInputChange?: (_v: string) => void;
  value?: T | null;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  className?: string;
  setPostionRef?: (_: number) => void;
  position?: number;
  labelKey: keyof T;
  label?: string;
  limitTags?: number;
  onOpen?: () => void;
  loading?: boolean;
  ref?: React.Ref<HTMLInputElement> | null;
  onChange: (_: T) => void;
  lastElementRef?: (_node: HTMLLIElement) => void;
}

const AutoCompleteSelect = <T = IProp,>({
  options,
  className = '',
  onInputChange,
  value = null,
  onChange,
  error,
  ref,
  label,
  onOpen,
  placeholder = 'Select',
  labelKey,
  loading,
  lastElementRef,
  position,
  setPostionRef,
  ...props
}: IAutoCompleteSelect<T>) => {
  const listElem = useRef<Element>();
  useEffect(() => {
    if (
      position &&
      position > 0 &&
      listElem?.current &&
      listElem?.current?.scrollTop !== position
    ) {
      listElem.current.scrollTop = position;
    }
  }, [options]);

  return (
    <div className="common_multi_select_container">
      {label && <p className="common_label_text">{label}</p>}
      <Autocomplete
        {...(setPostionRef
          ? {
              ListboxProps: {
                ref: listElem as RefObject<Element>,
                className: loading ? 'loading-scroll-bar' : '',
                onScroll: ({ currentTarget }) => {
                  const scrollPosition =
                    currentTarget?.scrollTop + currentTarget?.clientHeight;
                  if (setPostionRef) {
                    setPostionRef(scrollPosition);
                  }
                },
              },
            }
          : {})}
        loading={loading}
        onOpen={() => {
          onOpen && onOpen();
        }}
        id="combo-box-demo"
        className={`combo-textboxddd ${className}`}
        options={options}
        value={value}
        sx={{ border: 'none' }}
        popupIcon={<ArrowIcon />}
        ref={ref}
        onChange={(_, value) => {
          onChange(value as T);
        }}
        PaperComponent={({ children }) => (
          <Paper className="timezone-lists any-list">{children}</Paper>
        )}
        renderOption={(props, option, { index }) => {
          return (
            <li
              {...props}
              ref={
                index === options.length - 1 && lastElementRef
                  ? lastElementRef
                  : null
              }>
              <div>{option[labelKey] as string} </div>
            </li>
          );
        }}
        renderInput={params => (
          <TextField
            onChange={val => {
              onInputChange && onInputChange(val?.currentTarget?.value);
            }}
            {...params}
            className="text_field_input_multiselect"
            placeholder={placeholder}
          />
        )}
        {...props}
      />
      {error ? (
        <p className="error_message text-red-500 text-xs">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AutoCompleteSelect;

interface IControlledAutoCompleteSelect<T>
  extends Omit<IAutoCompleteSelect<T>, 'value' | 'onChange' | 'ref'> {
  name: string;
  onChangeHandler?: (_value: T) => string | null;
  newValueHandler?: (_value: string) => T | null;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledAutoCompleteSelect = <T,>({
  name,
  rules,
  onChangeHandler,
  newValueHandler,
  ...rest
}: IControlledAutoCompleteSelect<T>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, ref, onChange }, fieldState: { error } }) => {
        return (
          <AutoCompleteSelect<T>
            {...rest}
            ref={ref}
            value={newValueHandler ? newValueHandler(value) : value}
            onChange={e => onChange(onChangeHandler ? onChangeHandler(e) : e)}
            error={error?.message}
          />
        );
      }}
    />
  );
};
