import { Config, Data } from '@measured/puck';
import {
  Common,
  defaultComponentProps,
  mandatoryComponents,
  navigationPathComponents,
} from 'shared-components';
import { useState } from 'react';
import { ConvertJsonToBase64 } from 'shared-components';
import { useAppDispatch } from 'hooks/reduxHooks';
import {
  showNotification,
  StatusCode,
  toasterMessage,
} from 'shared-components';
import { ICheckoutPage } from 'shared-components';
import { PopUpModal } from 'shared-components';
import { Loader } from 'shared-components';
import _ from 'lodash';
import storeService from 'services/storeService';
import Editor from '../editor';
import { setMandatoryComponents } from 'shared-components/src/page-builder/redux/slices/commonSlice';

const extractUsedComponents = (data: Data) => {
  const set = new Set<string>();
  const allComponents: string[] = [];
  const usedNavigationFields: string[] = [];
  data.content.forEach(content => set.add(content.type as string));
  if (data.zones) {
    Object.values(data.zones).forEach(zone => {
      zone.forEach(c => {
        if (
          navigationPathComponents.includes(c.type as string) &&
          c?.props?.nextPagePath?.label === '' &&
          c?.props?.nextPagePath?.value === ''
        ) {
          usedNavigationFields.push(c.type as string);
        }
        set.add(c.type as string);
        allComponents.push(c.type as string);
      });
    });
  }
  return {
    usedNavigationFields: [...usedNavigationFields],
    usedComponents: [...Array.from(set)],
    allComponents: [...allComponents],
  };
};
interface IProps {
  data: Data;
  config: Config;
  checkoutPage: ICheckoutPage | null;
  onSuccessCallBack: () => void;
}
// Render Puck editor
const HomeEdit = ({
  data,
  config,
  checkoutPage,
  onSuccessCallBack,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [missingComponents, setMissingComponents] = useState<string[]>([]);
  const [formDuplicate, setFormDuplicate] = useState<string[]>([]);
  const [isDuplicateBilling, setDuplicateBilling] = useState<boolean>(false);
  const [isDuplicatePayment, setDuplicatePayment] = useState<boolean>(false);
  const [missingNavigationFields, setMissingNavigationFields] = useState<
    string[]
  >([]);
  const [stepperEmpty, setStepperEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Describe the initial data
  const template = new URLSearchParams(location.search).get('template') || '';
  const [isConfigUpdated, setConfigUpdated] = useState<string | null>(null);
  const [payload, setPayload] = useState<Data>();
  const [search, setSearch] = useState<string>('');

  // Save the data to your database
  const save = async () => {
    if (!payload) {
      return;
    }

    console.log(payload, template, `page data`);
    if (checkoutPage) {
      checkoutPage.PageData = await ConvertJsonToBase64(payload);
      setLoading(true);
      const res = await storeService.updateCheckoutPage(checkoutPage);
      setLoading(false);
      if (res.status === 200) {
        showNotification(StatusCode.success, toasterMessage.pageUpdateSuccess);
        onSuccessCallBack();
      }
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
    setConfigUpdated(null);
  };

  const handleSave = () => {
    setPopupOpen(false);
    setConfigUpdated(null);
    save();
  };

  const checkMandatoryComponents = (data: Data) => {
    const { usedComponents } = extractUsedComponents(data);
    return (
      mandatoryComponents[template]?.filter(
        component => !usedComponents?.find(c => c.startsWith(component)),
      ) ?? []
    );
  };

  const checkDuplicatePayment = (usedComponents: string[]) => {
    return (
      usedComponents.filter(item =>
        item.startsWith(defaultComponentProps.payment),
      ).length === 2
    );
  };

  const handleBillingAddressCheck = (
    payload: Data,
    usedComponents: string[],
    Component: string[],
  ) => {
    if (payload?.zones) {
      const isBillingAddress = Object.entries(payload.zones).some(
        ([, zoneItems]) =>
          zoneItems.some(
            item =>
              (item.type === 'Payment' || item.type === 'PaymentRadio') &&
              item.props.isShowBillingAddress,
          ),
      );

      if (isBillingAddress) {
        if (usedComponents.includes(defaultComponentProps.billingForm)) {
          setDuplicateBilling(true);
          Component.push(defaultComponentProps.billingForm);
        } else {
          removeComponent(Component, defaultComponentProps.billingForm);
        }
      }
    }
  };

  const removeComponent = (components: string[], componentToRemove: string) => {
    const indexToRemove = components.indexOf(componentToRemove);
    if (indexToRemove !== -1) {
      components.splice(indexToRemove, 1);
    }
  };

  const checkComponentDuplicates = (allComponents: string[] | undefined) => {
    if (!allComponents?.length) return [];
    const fieldsToCheck = [
      'ShippingForm',
      'BillingForm',
      'Payment',
      'ContactInfo',
      'ShippingMethodSelect',
      'PaymentRadio',
    ];

    const filteredComponent = allComponents.filter(
      (item): item is string =>
        typeof item === 'string' && fieldsToCheck.includes(item),
    );

    const duplicates = filteredComponent.reduce<Record<string, number>>(
      (acc, stepperItem) => {
        acc[stepperItem] = (acc[stepperItem] || 0) + 1;
        return acc;
      },
      {},
    );

    return Object.keys(duplicates).filter(key => duplicates[key] > 1);
  };

  const checkStepperEmpty = (payload: Data) => {
    if (!payload?.zones) return false;
    return Object.entries(payload.zones).some(
      ([name, zoneItems]) =>
        name?.includes('Stepper') && zoneItems.length === 0,
    );
  };

  const checkMissingNavigationFields = (payload: Data) => {
    if (!payload?.zones) return [];
    const missingNavigationFields: string[] = [];
    Object.values(payload.zones).forEach(zone => {
      zone.forEach(c => {
        if (
          navigationPathComponents.includes(c.type as string) &&
          c?.props?.nextPagePath?.label === '' &&
          c?.props?.nextPagePath?.value === ''
        ) {
          missingNavigationFields.push(c.type as string);
        }
      });
    });
    return missingNavigationFields;
  };

  const shouldUpdateConfig = (
    Component: string[],
    isPaymentDuplicate: boolean,
    duplicateItems: string[],
    usedNavigationFields: string[],
    isStepperEmpty: boolean,
  ) => {
    return (
      Component?.length === 0 &&
      !isPaymentDuplicate &&
      !duplicateItems?.length &&
      usedNavigationFields.length === 0 &&
      !isStepperEmpty
    );
  };

  const updatePopupState = (areEqual: boolean) => {
    setPopupOpen(true);
    if (!areEqual) {
      setConfigUpdated(Common.UNSAVED_CHANGES);
    } else {
      setConfigUpdated(Common.NO_CHANGES);
    }
  };

  const openAlert = (payload: Data) => {
    const { usedComponents, allComponents } = extractUsedComponents(payload);

    const isPaymentDuplicate = checkDuplicatePayment(usedComponents);
    setDuplicatePayment(isPaymentDuplicate);

    const Component = checkMandatoryComponents(payload);
    handleBillingAddressCheck(payload, usedComponents, Component);

    const duplicateItems = checkComponentDuplicates(allComponents);
    setFormDuplicate(duplicateItems.length ? duplicateItems : []);

    const isStepperEmpty = checkStepperEmpty(payload);
    setStepperEmpty(isStepperEmpty);

    setMissingComponents(Component);
    dispatch(setMandatoryComponents(Component));

    const missingNavigationFields = checkMissingNavigationFields(payload);
    setMissingNavigationFields(missingNavigationFields);
    if (Component?.length) setSearch('');

    if (
      shouldUpdateConfig(
        Component,
        isPaymentDuplicate,
        duplicateItems,
        missingNavigationFields,
        isStepperEmpty,
      )
    ) {
      setPayload(payload);
      const areEqual = _.isEqual(data, payload);
      updatePopupState(areEqual);
    } else {
      setPopupOpen(true);
    }
  };
  return (
    <div className="relative h-full">
      <Editor
        config={config}
        data={data}
        onPublish={openAlert}
        checkoutPage={checkoutPage}
        checkMandatoryComponents={checkMandatoryComponents}
        search={search}
        setSearch={setSearch}
      />
      <div>
        {isPopupOpen && (
          <PopUpModal
            open={isPopupOpen}
            handleClose={handleClose}
            buttons={
              isConfigUpdated === Common.UNSAVED_CHANGES
                ? [
                    {
                      buttonType: 'negative',
                      buttonLabel: 'No',
                      buttonFunction: handleClose,
                    },
                    {
                      buttonType: 'positive',
                      buttonLabel: 'Yes Proceed',
                      buttonFunction: handleSave,
                    },
                  ]
                : [
                    {
                      buttonType: 'negative',
                      buttonLabel: 'Close',
                      buttonFunction: handleClose,
                    },
                  ]
            }
            heading={''}>
            <div className="popup-section !w-full">
              {isConfigUpdated ? (
                <>
                  <p className="popup-question flex flex-wrap justify-center items-center">
                    {isConfigUpdated}
                  </p>
                </>
              ) : (
                <>
                  {isDuplicateBilling ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Having multiple billing address components, please remove
                      any one.
                    </p>
                  ) : isDuplicatePayment ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Please remove any one payment component.
                    </p>
                  ) : formDuplicate.length ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      You have multiple {formDuplicate?.join(', ')} components.
                      Please remove any one of them.
                    </p>
                  ) : missingComponents?.length > 0 ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Please add {missingComponents?.join(', ')} components to
                      publish the page.
                    </p>
                  ) : missingNavigationFields?.length > 0 ? (
                    <p className="popup-question flex flex-wrap justify-center items-center">
                      Please add navigation Path for{' '}
                      {missingNavigationFields?.join(', ')} components to
                      publish the page.
                    </p>
                  ) : (
                    stepperEmpty && (
                      <p className="popup-question flex flex-wrap justify-center items-center">
                        Please add atleast one step in stepper to publish the
                        page.
                      </p>
                    )
                  )}
                </>
              )}
            </div>
          </PopUpModal>
        )}
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default HomeEdit;
