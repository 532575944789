import { TermsAndSubscription } from '../terms-and-subscription/TermsAndSubscription';
import { useLocation } from 'react-router-dom';
import { ComponentConfig, FieldLabel } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { QuillEditor } from '../../../components/common/quill-editor/quill-editor';
import { QuillTextEditor } from '../../puck/reusable-props/quill-editor';
import { Switch, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Path } from '../../utils/constants';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
export type ExtraTermProps = {
  viewMoreTerms: boolean;
  moreTerms: string;
};
export type TermsAndSubProps = {
  acknowledgeContent: string;
  extraTerms: ExtraTermProps;
} & MarginProps;

export const TermsAndSub = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps &
  ReduxSelectorProps): ComponentConfig<TermsAndSubProps> => {
  return {
    label: (
      <CustomTooltip title="TermsAndSub">
        <span>Terms and Subscription</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      acknowledgeContent: {
        ...QuillTextEditor('Terms and Subscription').content,
      },
      ...marginFields,
      extraTerms: {
        type: 'custom',
        render: ({ value, onChange }) => {
          const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
            value.viewMoreTerms = e.target.checked;
            onChange(value);
          };
          const handleQuillChanges = (content: string) => {
            value.moreTerms = content;
            onChange(value);
          };
          return (
            <>
              <div className="flex justify-between items-center mb-3">
                <p className="text-sm font-semibold"> Show Extra Terms</p>
                <div className="flex gap-0.5 items-center">
                  <Tooltip
                    title={`Extra terms will be displayed when user clicks on "Learn More"`}
                    placement="top-start">
                    <InfoIcon fontSize="small" className="h-1 w-1" />
                  </Tooltip>
                  <Switch
                    checked={value?.viewMoreTerms}
                    onChange={handleChanges}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </div>
              {value?.viewMoreTerms && (
                <FieldLabel label="Extra Terms" el="div">
                  <QuillEditor
                    value={value.moreTerms}
                    onChange={handleQuillChanges}
                  />
                </FieldLabel>
              )}
            </>
          );
        },
      },
    },
    defaultProps: {
      acknowledgeContent:
        '<p><span style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);">I acknowledge that I have read the </span><span style="background-color: rgb(255, 255, 255); color: rgb(79, 70, 229);">Privacy Policy</span><span style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);"> and the </span><span style="background-color: rgb(255, 255, 255); color: rgb(79, 70, 229);">Terms of Service</span><span style="background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);">. I agree to the Auto-Renewal Payment Authorization above.</span></p>',
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      extraTerms: {
        viewMoreTerms: false,
        moreTerms:
          '<ul><li>The VIP Membership provides access to VIP Pricing on all products on the website, priority access to 24/7 customer support, free shipping on all membership portal orders, and exclusive new product releases.</li></ul><p><br></p><ul><li>Automatic Billing. Your credit card or other payment method on file will be charged the VIP Membership fee of 14 dollars and 99 cents USD (exclusive of taxes) every 30 days without obtaining your further confirmation or revised payment information. The first charge will occur 12 days after purchase and then every 30 days after. You may be charged less, but never more than the Membership Fee without your revised consent.</li></ul><p><br></p><ul><li>Cancellation. If you wish to cancel the VIP Membership at any time, you may do so by filling out the&nbsp;<span style="color: rgb(137, 8, 8);">Cancel Membership</span>&nbsp;form on our Website, by emailing us at support@valhallafallen.com or by calling us at +1 800-304-5759 at least 3 days before the next billing period with your full name and the email and physical address associated with your use of the Service. Your failure to cancel or your continued use of the VIP Membership reaffirms that Company is authorized to charge you for, and you are responsible for paying, all VIP Membership fees.</li></ul>',
      },
    },
    render: ({ margin, acknowledgeContent, extraTerms }) => {
      const { pathname } = useLocation();
      const isEditModeOn = pathname.includes(Path.PAGE_BUILDER_EDIT);
      return (
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <TermsAndSubscription
            acknowledgeContent={acknowledgeContent}
            extraTerms={extraTerms}
            isEditModeOn={isEditModeOn}
            useAppDispatch={useAppDispatch}
            useAppSelector={useAppSelector}
          />
        </div>
      );
    },
  };
};
