import { useForm, Controller } from 'react-hook-form';
import { Button, ToggleSwitch } from 'shared-components';
import 'components/customers/customersFilter/filterForm/style.scss';
import DatePicker from 'components/common/datePicker';
import { FC } from 'react';
import MultiSelect from 'components/common/multiSelect';
import { IDefaultOption, IFormFilter } from 'interface/customerInterface';
import { defaultCustomerFilterValues } from 'components/customers';
import { MultipleInputTag } from 'components/common/multiInput';
import { emailRegex, trafficChannelData } from 'utils/constants';
import dayjs from 'dayjs';
import TextField from 'components/common/textField';

interface IProps {
  sideFormFilter: IFormFilter;
  filterSubmission: (_formData: IFormFilter) => void;
  resetSelected: () => void;
}

const FilterForm: FC<IProps> = ({
  sideFormFilter,
  filterSubmission,
  resetSelected,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    watch,
  } = useForm<IFormFilter>({
    defaultValues: sideFormFilter,
  });

  const onSubmit = (data: IFormFilter) => {
    filterSubmission(data);
    resetSelected();
  };

  const subscriptionStatus: IDefaultOption[] = [
    { label: 'Active', value: 'active' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Salvage', value: 'salvage' },
    { label: 'Paused', value: 'paused' },
  ];

  return (
    <div className="filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        {/* <Controller
          name="StoreIDs"
          control={control}
          render={({ field: { value, onChange } }) => (
            <MultiStoreSearch value={value} onChange={onChange} />
          )}
        /> */}
        <Controller
          name="CustomerEmails"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultipleInputTag
                tagsValue={value}
                label="Customer Emails"
                isLowerCase={true}
                validation={[
                  {
                    validationRule: emailRegex,
                    validationMessage: 'Enter a valid email address',
                  },
                ]}
                setError={(value: string) => {
                  setError('CustomerEmails', {
                    type: 'custom',
                    message: value,
                  });
                }}
                placeholder="Type Emails"
                onChange={onChange}
                errorString={error?.message}
              />
            );
          }}
        />
        <Controller
          name="PhoneNumbers"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleInputTag
                tagsValue={value}
                label="Phone Numbers"
                placeholder="Type Phone Numbers"
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="FirstName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="First Name"
                placeholder="Type First Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="LastName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="Last Name"
                placeholder="Type Last Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="TrafficChannels"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={trafficChannelData}
                labelKey="label"
                valueKey="value"
                label="Traffic Channel"
                placeholder="Select Traffic Channel"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.Channels?.message}
              />
            );
          }}
        />
        <Controller
          name="SubscriptionStatus"
          control={control}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <MultiSelect<IDefaultOption>
                value={value}
                options={subscriptionStatus}
                labelKey="label"
                valueKey="value"
                label="Subscription Status"
                placeholder="Select Subscription Status"
                ref={ref}
                limitTags={1}
                onChange={(newValue: IDefaultOption[]) => {
                  onChange(newValue);
                }}
                error={errors?.TrafficChannels?.message}
              />
            );
          }}
        />
        <div className="direct_sale_box">
          <p className="common_input_label">Acquisition Date</p>
          <div className="flexContainer">
            <Controller
              name="StartTime"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    maxDate={dayjs(watch('EndTime')).tz()}
                    disableFuture={true}
                    error={errors?.StartTime?.message}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="EndTime"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <DatePicker
                    error={errors?.EndTime?.message}
                    minDate={dayjs(watch('StartTime')).tz()}
                    disableFuture={true}
                    ref={ref}
                    {...rest}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="flexBox togglerContainer spaceBetween alignCenter">
          <p className="common_input_label">Disputed before?</p>
          <Controller
            name="HasDisputed"
            control={control}
            render={({ field: { ref, value, ...rest } }) => {
              return (
                <ToggleSwitch
                  {...rest}
                  checked={value}
                  className="filter_form_input"
                  ref={ref}
                />
              );
            }}
          />
        </div>
        <div className="flexBox togglerContainer spaceBetween alignCenter">
          <p className="common_input_label">Blacklist?</p>
          <Controller
            name="IsBlacklisted"
            control={control}
            render={({ field: { ref, value, ...rest } }) => {
              return (
                <ToggleSwitch
                  {...rest}
                  checked={value}
                  className="filter_form_input"
                  ref={ref}
                />
              );
            }}
          />
        </div>
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              reset(defaultCustomerFilterValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
