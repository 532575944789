import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  PaddingProps,
  paddingFields,
} from '../../../page-builder/puck/reusable-props/padding';

import { Vip } from './vip';
import { QuillTextEditor } from '../../../page-builder/puck/reusable-props/quill-editor';
import { ReduxSelectorProps } from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import { CustomizeProps } from '../../interface/commonInterface';
import TextCustomInput from '../../puck/reusable-props/TextCustomInput';

export type VipProps = {
  content: string;
  Title: CustomizeProps;
  Description: CustomizeProps;
  BenefitTitle: CustomizeProps;
  BenefitDescription: CustomizeProps;
};

export type VipComponentProps = VipProps & MarginProps & PaddingProps;

export const VipComponent = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<VipComponentProps> => {
  return {
    label: (
      <CustomTooltip title="VipComponent">
        <span>VIP Component</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      Title: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput value={value} onChange={onChange} title="Title" />
          );
        },
      },
      Description: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              title="Description"
            />
          );
        },
      },
      BenefitTitle: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              title="Benefit Title"
            />
          );
        },
      },
      BenefitDescription: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              title="Benefit Description"
            />
          );
        },
      },
      content: {
        ...QuillTextEditor('Cancellation Description').content,
      },
      ...marginFields,
      ...paddingFields,
    },
    defaultProps: {
      Title: {
        text: 'VIP Club',
        textColor: '#000000',
        fontSize: '16',
        fontWeight: 'font-normal',
        hoverColor: '#000000',
      },
      Description: {
        text: 'By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.',
        textColor: '#000000',
        fontSize: '16',
        fontWeight: 'font-normal',
        hoverColor: '#000000',
      },
      BenefitTitle: {
        text: 'The VIP Club is an exclusive program that provides you with',
        textColor: '#000000',
        fontSize: '16',
        fontWeight: 'font-normal',
        hoverColor: '#000000',
      },
      BenefitDescription: {
        text: `Access to VIP pricing on all website products.,
    Access to hundreds of trending products through the VIP Portal.,
    Lowest price guarantee on all VIP Portal orders.,
    Priority 24/7 customer support.`,
        textColor: '#000000',
        fontSize: '16',
        fontWeight: 'font-normal',
        hoverColor: '#000000',
      },
      content: '',
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-4',
          bottom: 'pb-4',
          right: 'pr-4',
          left: 'pl-4',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
    },
    render: ({
      margin,
      padding,
      content,
      Title,
      BenefitDescription,
      BenefitTitle,
      Description,
    }) => {
      return (
        <div
          className={`flex flex-col w-full
        ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
        ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
        ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
        ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
        ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
        ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}>
          <Vip
            Title={Title}
            BenefitDescription={BenefitDescription}
            BenefitTitle={BenefitTitle}
            Description={Description}
            content={content}
            useAppSelector={useAppSelector}
          />
        </div>
      );
    },
  };
};
