import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import CustomTooltip from '../../../components/common/tooltip';
import BillForm from './form/BillingForm';

export type BillingFormProps = MarginProps;

export const BillingForm: ComponentConfig<BillingFormProps> = {
  label: (
    <CustomTooltip title="BillingForm">
      <span>Billing Form</span>
    </CustomTooltip>
  ) as React.ReactNode as string,
  fields: {
    ...marginFields,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin }) => {
    return (
      <>
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <BillForm />
        </div>
      </>
    );
  },
};
