import React, { useMemo } from 'react';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as SubscriptionRatio } from 'assets/icons/SubscriptionRatio.svg';
import { ReactComponent as StraightRatio } from 'assets/icons/StraightRatio.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import {
  LifetimeStats,
  LifetimeSubscriptionStatsData,
  UniqueAprrovalStats,
} from 'interface/dashboardNewIterface';
import { chargeType, transactionStatus } from './constant';
import { ISubscriptionStats } from 'interface/dashboardInterface';
import { Tooltip } from '@mui/material';
import { formattedDate, percentage } from 'utils/helper';
import dayjs from 'dayjs';
import { IDashboardFilter } from '.';

interface IStatsCardProps {
  uniqueTransactionStats: UniqueAprrovalStats[] | null;
  subscriptionStats: ISubscriptionStats | null;
  lifeTimeStats: LifetimeStats[] | null;
  transactionStats: LifetimeSubscriptionStatsData[] | null;
  filter?: IDashboardFilter;
}
export const approvedTransactionStatus = [
  transactionStatus.captured,
  transactionStatus.authorized,
];

export default function StatsCards({
  uniqueTransactionStats,
  subscriptionStats,
  filter,
}: IStatsCardProps) {
  const cardStats = useMemo(() => {
    let saleApproved = 0;
    let saleTotal = 0;
    let subApproved = 0;
    let subTotal = 0;
    let subCycleInitial = 0;
    let subCycleRecurring = 0;
    let initialTotal = 0;
    let recurringTotal = 0;
    let purchaseTotal = 0;
    let purchaseApproved = 0;
    let upSellTotal = 0;
    let upSellApproved = 0;
    const totalSubscriptionOrderCount = 0;

    if (!uniqueTransactionStats || !uniqueTransactionStats?.length) {
      return {
        recurringTotal,
        initialTotal,
        purchaseTotal,
        upSellTotal,
        saleTotal,
        purchaseApproved,
        subTotal,
        totalStraightApprovalPercentage: 0,
        totalSubscriptionApprovalPercentage: 0,
        totalSubCycleIntitalPercentage: 0,
        totalSubCycleRecurringPercentage: 0,
        totalSubscriptionOrderCount,
        totalPurchasePercentage: 0,
        totalUpSellPercentage: 0,
      };
    }
    uniqueTransactionStats.forEach(stat => {
      if (stat.ChargeType === chargeType.subscription) {
        subTotal += stat.Count;
        subApproved += stat.Count;
        if (stat.Initial) {
          initialTotal += stat.Count;
          subCycleInitial += stat.Count;
          if (stat.Declined) {
            subApproved -= stat.Count;
            subCycleInitial -= stat.Count;
          }
          return;
        }
        recurringTotal += stat.Count;
        subCycleRecurring += stat.Count;
        if (stat.Declined) {
          subApproved -= stat.Count;
          subCycleRecurring -= stat.Count;
        }
        return;
      } else if (stat.ChargeType === chargeType.upsell) {
        saleTotal += stat.Count;
        saleApproved += stat.Count;
        upSellTotal += stat.Count;
        upSellApproved += stat.Count;
        if (stat.Declined) {
          saleApproved -= stat.Count;
          upSellApproved -= stat.Count;
        }
        return;
      }
      saleTotal += stat.Count;
      saleApproved += stat.Count;
      purchaseTotal += stat.Count;
      purchaseApproved += stat.Count;
      if (stat.Declined) {
        saleApproved -= stat.Count;
        purchaseApproved -= stat.Count;
      }
    });
    return {
      recurringTotal,
      initialTotal,
      purchaseTotal,
      upSellTotal,
      saleTotal,
      purchaseApproved,
      subTotal,
      totalStraightApprovalPercentage: percentage(
        saleApproved,
        saleTotal,
        true,
      ),
      totalSubscriptionApprovalPercentage: percentage(
        subApproved,
        subTotal,
        true,
      ),
      totalSubCycleIntitalPercentage: percentage(
        subCycleInitial,
        initialTotal,
        true,
      ),
      totalSubCycleRecurringPercentage: percentage(
        subCycleRecurring,
        recurringTotal,
        true,
      ),
      totalUpSellPercentage: percentage(upSellApproved, upSellTotal, true),
      totalPurchasePercentage: percentage(
        purchaseApproved,
        purchaseTotal,
        true,
      ),
      totalSubscriptionOrderCount: subApproved,
    };
  }, [uniqueTransactionStats]);

  const percentagePlaceholder = (percentage: number, total: number) => {
    return percentage ? `${percentage}%` : total ? '0' : '-';
  };
  return (
    <div className="statistics_cards row-full">
      <div className="card-wrapper">
        <p className="date">
          {formattedDate(dayjs(filter?.start_time).tz().format(), true) +
            ' - ' +
            formattedDate(dayjs(filter?.end_time).tz().format(), true)}
        </p>
        <div className="cards">
          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Direct</p>
                  <p className="title">
                    {' '}
                    {percentagePlaceholder(
                      cardStats.totalPurchasePercentage,
                      cardStats.purchaseTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">UpSell</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalUpSellPercentage,
                      cardStats.upSellTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <StraightRatio className="net_revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Straight Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalStraightApprovalPercentage,
                    cardStats.saleTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Initial</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleIntitalPercentage,
                      cardStats.initialTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">Recurring</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleRecurringPercentage,
                      cardStats.recurringTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <SubscriptionRatio className="revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Subscription Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalSubscriptionApprovalPercentage,
                    cardStats.subTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>

          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Purchase Orders</p>
              <p className="sub_text">{cardStats.purchaseApproved}</p>
            </div>
          </div>

          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscription Orders</p>
              <p className="sub_text">
                {cardStats.totalSubscriptionOrderCount}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper">
        <p className="date">Lifetime</p>
        <div className="cards">
          <div className="card">
            <div className="icon_wrapper subs_revenue">
              <UserIcon className="active_subscribers" />
            </div>
            <div className="content_wrapper">
              <p className="label">Active Subscribers</p>
              <p className="sub_text">
                {
                  subscriptionStats?.LifetimeSubscriptionStats
                    ?.ActiveSubscriptions
                }
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper str_sale ">
              <UserIcon className="salvage" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscribers in Salvage</p>
              <p className="sub_text">
                {' '}
                {subscriptionStats?.LifetimeSubscriptionStats?.InSalvage}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper str_sale ">
              <UserIcon className="salvage" />
            </div>
            <div className="content_wrapper">
              <p className="label">Cancelled Subscribers</p>
              <p className="sub_text">
                {' '}
                {
                  subscriptionStats?.LifetimeSubscriptionStats
                    ?.CanceledSubscriptions
                }
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
