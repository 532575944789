/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputHTMLAttributes, useMemo } from 'react';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as ColoumnFilter } from 'assets/icons/columnFilter-icon.svg';
import FilterForm from 'components/transactions/transactionFilter/filterForm';
import ColumnFilter from 'components/transactions/transactionFilter/columnFilter';
import ActionFilter from 'components/transactions/transactionFilter/actionFilter';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { HeadCell } from 'components/transactions/index';
import {
  ITransactionFormFilter,
  ITransactionsData,
} from 'interface/transactionInterface';
import FilterAction from 'components/common/filterAction';
import { setMyColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import ExportFilter from './ExportFilter';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { userPermissionKeys } from 'utils/constants';

interface ISideFilter extends InputHTMLAttributes<HTMLInputElement> {
  headCellList: HeadCell[];
  setHeadCellList: (headCell: HeadCell[]) => void;
  selected: Map<string, ITransactionsData>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTransactionList: React.Dispatch<React.SetStateAction<ITransactionsData[]>>;
  sideFormFilter: ITransactionFormFilter;
  searchTransactionList: () => void;
  filterSubmission: (_data: ITransactionFormFilter) => void;
  resetSelected: () => void;
  exportList: () => void;
}
const TransactionActions = {
  fail_authorization: ['Tag'],
  captured: ['Refund', 'Tag', 'Mark Chargeback', 'Record Dispute'],
  authorized: ['Void', 'Capture', 'Tag', 'Mark Chargeback', 'Record Dispute'],
  refunded: ['Tag', 'Mark Chargeback'],
  chargeback: ['Tag', 'Record Dispute'],
};

const SideFilter: React.FC<ISideFilter> = ({
  headCellList,
  setHeadCellList,
  selected,
  setLoading,
  setTransactionList,
  sideFormFilter,
  filterSubmission,
  searchTransactionList,
  resetSelected,
  exportList,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { transactionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );
  const { transaction } = useAppSelector(state => state.permission);
  const filterAction = [
    { key: 'Filters', icon: FilterIcon, className: 'margin_filter' },
    { key: 'Actions', icon: ActionIcon, permission: userPermissionKeys.manage },
    { key: 'Column Filters', icon: ColoumnFilter },
    { key: 'Download', icon: DownloadIcon },
  ];

  const handleChange = (val: string) => {
    if (transactionColumnFilter.indexOf(val) === -1) {
      dispatch(
        setMyColoumnDetails({
          transactionColumnFilter: [...transactionColumnFilter, val],
        }),
      );
    } else {
      dispatch(
        setMyColoumnDetails({
          transactionColumnFilter: [
            ...transactionColumnFilter.filter(each => each !== val),
          ],
        }),
      );
    }
    setHeadCellList(
      headCellList.map((each: HeadCell) =>
        each.label === val ? { ...each, hide: !each.hide } : each,
      ),
    );
  };

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'transactions', currentFilter: filter }),
    );
  };

  const filteredActions = useMemo(() => {
    const checkTransactionStatus = () => {
      let hasCaptured = true;
      let hasAuthorized = true;
      let hasRefunded = true;
      let hasChargeback = true;

      for (const value of selected.values()) {
        if (value.Status !== 'captured' && value.Status !== 'partial_refund') {
          hasCaptured = false;
        }
        if (value.Status !== 'authorized') {
          hasAuthorized = false;
        }
        if (value.Status !== 'refunded') {
          hasRefunded = false;
        }
        if (value.Status !== 'chargeback') {
          hasChargeback = false;
        }

        // If both checks are false, you can break early as the result will be fail_authorization
        if (!hasCaptured && !hasAuthorized) {
          break;
        }
      }
      if (hasCaptured) {
        return TransactionActions.captured;
      }

      if (hasAuthorized) {
        return TransactionActions.authorized;
      }
      if (hasRefunded) {
        return TransactionActions.refunded;
      }
      if (hasChargeback) {
        return TransactionActions.chargeback;
      }
      return TransactionActions.fail_authorization;
    };

    if (selected) {
      return checkTransactionStatus();
    } else {
      return TransactionActions.captured;
    }
  }, [selected]);

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFormFilter={sideFormFilter}
            filterSubmission={filterSubmission}
            resetSelected={resetSelected}
          />
        );
      case 'Column Filters':
        return (
          <ColumnFilter
            filterList={headCellList}
            setSelectedColumns={handleChange}
          />
        );
      case 'Actions':
        return (
          <ActionFilter
            searchTransactionList={searchTransactionList}
            selected={selected}
            filterList={filteredActions}
            setLoading={setLoading}
            setTransactionList={setTransactionList}
            resetSelected={resetSelected}
          />
        );
      case 'Download':
        return <ExportFilter exportList={exportList} />;
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={transaction}
        setSelectedFilter={setSelectedFilter}
      />

      {!!currentFilter && filterSource === 'transactions' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
