import React from 'react';
import { ComponentConfig } from '@measured/puck';
import { MarginProps, marginFields } from '../../puck/reusable-props/margin';

import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import PaymentRadioForm from './PaymentRadioForm';
import { CustomizeProps } from '../../interface/commonInterface';
import TextCustomInput from '../../puck/reusable-props/TextCustomInput';

export type PaymentRadioProps = {
  subText: CustomizeProps;
  isShowBillingAddress?: boolean;
  showCardName?: boolean;
} & MarginProps;

export const PaymentRadio = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps &
  ReduxSelectorProps): ComponentConfig<PaymentRadioProps> => {
  return {
    label: (
      <CustomTooltip title="PaymentRadio">
        <span>Payment Radio</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      subText: {
        type: 'custom',
        label: 'Sub Text',
        render: ({ value, onChange }) => (
          <TextCustomInput value={value} onChange={onChange} title="Sub Text" />
        ),
      },
      ...marginFields,
      isShowBillingAddress: {
        type: 'radio',
        label: 'Show Billing Address',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      showCardName: {
        type: 'radio',
        label: 'Show Card Name',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    },
    defaultProps: {
      subText: {
        text: 'All transactions are secured and encrypted',
        fontSize: '16px',
        fontWeight: 'normal',
        textColor: '#000000',
        hoverColor: '#000000',
      },
      isShowBillingAddress: true,
      showCardName: true,
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin, isShowBillingAddress, subText, showCardName }) => {
      return (
        <fieldset
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
      ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
      ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <PaymentRadioForm
            useAppDispatch={useAppDispatch}
            useAppSelector={useAppSelector}
            fields={{ margin, isShowBillingAddress, subText, showCardName }}
          />
        </fieldset>
      );
    },
  };
};
