import React, { useState } from 'react';
import CreditCardLogos from '../../../assets/svg/credit-logos';
import Afterpay from '../../../assets/svg/after-pay';
import SezzleLogo from '../../../assets/svg/sezzle';
import { RadioGroup } from '@headlessui/react';
import { PaymentController } from '../payment/PaymentController';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import { PaymentProps } from '../payment/Payment';

interface PaymentMethod {
  id: string;
  logo: React.JSX.Element;
  component: React.JSX.Element;
}

interface PaymentLogoSectionProps {
  name: string;
  LogoComponent: React.ElementType;
  logoProps?: React.ComponentProps<'img'>;
}

const PaymentLogoSection: React.FC<PaymentLogoSectionProps> = ({
  name,
  LogoComponent,
  logoProps,
}) => {
  return (
    <div className="flex justify-between items-center w-full">
      <p className="text-sm text-black">{name}</p>
      <LogoComponent className="pb-1" {...logoProps} />
    </div>
  );
};

const CurrentlyNotAvailable = () => {
  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-[10px] sm:grid-cols-4 items-center justify-center w-full h-full p-5 border-t border-gray-200 mt-2">
      <p className="text-gray-500 text-sm w-full col-span-4 text-center">
        Currently not available
      </p>
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface PaymentRadioFormProps {
  fields: PaymentProps;
}

const PaymentRadioForm = ({
  useAppDispatch,
  useAppSelector,
  fields,
}: ReduxDispatchProps & ReduxSelectorProps & PaymentRadioFormProps) => {
  const paymentMethods: PaymentMethod[] = [
    {
      id: 'creditCard',
      logo: (
        <PaymentLogoSection
          name="Credit card"
          LogoComponent={CreditCardLogos}
          logoProps={{ className: 'h-9' }}
        />
      ),
      component: (
        <PaymentController
          useAppDispatch={useAppDispatch}
          useAppSelector={useAppSelector}
          fields={fields}
          fromRadio
        />
      ),
    },
    {
      id: 'sezzle',
      logo: (
        <PaymentLogoSection
          name="Buy Now, Pay Later with Sezzle"
          LogoComponent={SezzleLogo}
          logoProps={{ className: 'h-9 w-12' }}
        />
      ),
      component: <CurrentlyNotAvailable />,
    },
    {
      id: 'afterpay',
      logo: (
        <PaymentLogoSection
          name="Afterpay"
          LogoComponent={Afterpay}
          logoProps={{ className: 'h-12' }}
        />
      ),
      component: <CurrentlyNotAvailable />,
    },
  ];

  const [selected, setSelected] = useState(paymentMethods[0].id);
  return (
    <>
      <p
        className={`mb-2 ${fields?.subText?.fontWeight}`}
        style={{
          color: `${fields?.subText?.textColor}`,
          fontSize: `${fields?.subText?.fontSize}px`,
        }}>
        {fields?.subText?.text}
      </p>
      <RadioGroup
        value={selected}
        onChange={newValue => setSelected(newValue)}
        className="rounded-md bg-white">
        {paymentMethods.map((pm, index) => (
          <RadioGroup.Option
            key={pm.id}
            value={pm.id}
            className={({ checked }) =>
              classNames(
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === paymentMethods.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'shipping-border z-10 border-indigo-200'
                  : 'border-gray-200',
                'relative border py-4 px-4 focus:outline-none',
              )
            }>
            {({ checked }) => (
              <>
                <div className="flex items-center w-full">
                  <span
                    className={classNames(
                      checked
                        ? 'check-box border-transparent bg-page_builder_theme'
                        : 'border-gray-300 bg-white',
                      'h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border flex',
                    )}
                    aria-hidden="true">
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span className="flex-none w-full pl-2 pr-4">{pm.logo}</span>
                </div>
                {checked && <div className="mt-2">{pm.component}</div>}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </>
  );
};

export default PaymentRadioForm;
