import { useMemo, FC, useState } from 'react';
import { chargeType } from 'components/dashboard/constant';
import ButtonGroup from 'components/common/ButtonGroup';
import { UsDollarFormatter } from 'utils/helper';
import { LifetimeSubscriptionStatsData } from 'interface/dashboardNewIterface';
import { ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { filledLineChartoptions } from './LineChart/constant';

interface IProps {
  groupedTransactionStats: [string, LifetimeSubscriptionStatsData[]][];
}
interface IDatasets {
  subscription: number[];
  straight: number[];
  upSell: number[];
  total: number[];
}

const revenueTypes = {
  totalRevenue: 'Total Revenue',
  netRevenue: 'Net Revenue',
};
const filterButtons = Object.values(revenueTypes).map(types => ({
  name: types,
}));

const SalesRevenue: FC<IProps> = ({ groupedTransactionStats }) => {
  const [revenueType, setRevenueType] = useState(revenueTypes?.totalRevenue);

  const salesRevenueData = useMemo(() => {
    const values: IDatasets = {
      straight: [],
      upSell: [],
      subscription: [],
      total: [],
    };
    groupedTransactionStats.forEach(([, statsData]) => {
      const { straight, subscription, upsell, total } = statsData.reduce(
        ({ straight, subscription, upsell }, stat) => {
          const losses = stat?.Losses || 0;
          if (stat?.Kind === chargeType?.subscription) {
            subscription += stat?.Revenue + losses;
            if (revenueType === revenueTypes?.netRevenue && stat?.Losses) {
              subscription -= stat?.Losses;
            }
            return {
              straight,
              upsell,
              subscription,
              total: straight + subscription,
            };
          } else if (stat?.Kind === chargeType?.upsell) {
            upsell += stat?.Revenue + losses;
            straight += stat?.Revenue + losses;
            if (revenueType === revenueTypes?.netRevenue && stat?.Losses) {
              upsell -= stat?.Losses;
              straight -= stat?.Losses;
            }
            return {
              straight,
              upsell,
              subscription,
              total: straight + subscription,
            };
          }
          straight += stat?.Revenue + losses;
          if (revenueType === revenueTypes?.netRevenue && stat?.Losses) {
            straight -= stat?.Losses;
          }
          return {
            straight,
            upsell,
            subscription,
            total: straight + subscription,
          };
        },
        {
          straight: 0,
          subscription: 0,
          upsell: 0,
          total: 0,
        },
      );
      values.straight.push(Number(straight?.toFixed(2)));
      values.subscription.push(Number(subscription?.toFixed(2)));
      values.upSell.push(Number(upsell?.toFixed(2)));
      values.total.push(Number(total?.toFixed(2)));
    });
    const overallTotal = values.total.reduce(
      (prev, current) => prev + current,
      0,
    );
    const subscriptionTotal = values.subscription.reduce(
      (prev, current) => prev + current,
      0,
    );
    const straightTotal = values.straight.reduce(
      (prev, current) => prev + current,
      0,
    );

    const upSellTotal = values.upSell.reduce(
      (prev, current) => prev + current,
      0,
    );

    return {
      topContainer: [
        {
          legend: 'Total',
          total: overallTotal,
        },
        {
          legend: 'Subscription',
          borderColor: '#F90182',
          total: subscriptionTotal,
        },
        {
          legend: 'Straight Sale',
          borderColor: '#6AD2FF',
          total: straightTotal,
        },
        {
          legend: 'UpSell',
          borderColor: '#1243d8',
          total: upSellTotal,
        },
      ],
      chart: {
        labels: groupedTransactionStats.map(([date]) => date),
        datasets: [
          {
            label: 'Subscription',
            borderColor: 'rgba(249, 1, 130, 1)',
            legend: 'Subscription Revenue',
            data: values.subscription,
            total: subscriptionTotal,
            OverallTotal: values.total.map((_, index) => {
              return UsDollarFormatter(
                values?.subscription?.[index] + values?.straight?.[index],
              );
            }),
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(249, 1, 130, 0.5)'); // Darker Rose with 50% opacity
              gradient.addColorStop(1, 'rgba(249, 1, 130, 0)'); // Transparent Rose
              return gradient;
            },
            pointBorderColor: 'white',
            pointBackgroundColor: '#05CD99',
            fill: true,
          },
          {
            fill: true,
            label: 'Straight',
            borderColor: '#6AD2FF',
            legend: 'Straight Sale Revenue',
            pointBorderColor: 'white',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(106, 210, 255, 0.42)'); // Slightly lighter blue with 42% opacity
              gradient.addColorStop(1, 'rgba(106, 210, 255, 0)'); // Fully transparent blue
              return gradient;
            },
            data: values.straight,
            OverallTotal: values.total,
            total: straightTotal,
          },
          {
            fill: true,
            label: 'UpSell',
            borderColor: '#1243d8',
            legend: 'UpSell Revenue',
            pointBorderColor: 'white',
            backgroundColor: (context: ScriptableContext<'line'>) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(18, 67, 216, 0.42)'); // Lighter shade with 42% opacity
              gradient.addColorStop(1, 'rgba(18, 67, 216, 0)'); // Fully transparent at the bottom
              return gradient;
            },
            data: values.upSell,
            OverallTotal: values.total,
            total: upSellTotal,
          },
        ],
      },
      totalSubscription: subscriptionTotal,
      totalStraight: subscriptionTotal,
      totalUpSell: upSellTotal,
      total: overallTotal,
    };
  }, [groupedTransactionStats, revenueType]);
  return (
    <div className="lg_card_container">
      <div className="header">
        <p className="header_text">Sales Revenue</p>
        <ButtonGroup
          active={revenueType}
          setActive={setRevenueType}
          buttons={filterButtons}
        />
      </div>
      <div className="body">
        <div className="left_side_details">
          <div className="stats_details">
            {salesRevenueData?.topContainer?.map(data => {
              return (
                <div key={data.legend} className="flex flex-col items-start">
                  <p className="head_text">
                    <span
                      className="indicator"
                      style={{ background: data?.borderColor }}
                    />
                    {data?.legend}
                  </p>
                  <p className="sub_text">$ {UsDollarFormatter(data?.total)}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart_wrapper">
          <Line
            options={filledLineChartoptions(salesRevenueData.chart.labels, '$')}
            data={salesRevenueData?.chart}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesRevenue;
