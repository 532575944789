import { ComponentConfig } from '@measured/puck';
import { useState } from 'react';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { imageField } from '../../../page-builder/puck/reusable-props/image';
import {
  ImageProps,
  imageProps,
} from '../../../page-builder/puck/reusable-props/image-props';
import { CartIcon } from '../../../assets/icons/cartIcon';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import { ReduxSelectorProps } from '../../services/constants';
import { AxiosInstance } from 'axios';
import { paddingFields, PaddingProps } from '../../puck/reusable-props/padding';
import CustomTooltip from '../../../components/common/tooltip';
import { Path } from '../../utils/constants';
import { CustomizeProps } from '../../interface/commonInterface';
import TextCustomInput from '../../puck/reusable-props/TextCustomInput';
import ColorSelector from '../../puck/reusable-props/color-selector';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export type SimplePageHeaderProps = {
  imageFile: IAsset;
  backgroundImageFile: IAsset;
  absolute: boolean;
  logoAlign: boolean;
  showHeader: boolean;
  CustomizeFonts: CustomizeProps;
  background: {
    backgroundColor: string;
    hoverColor: string;
  };
  show: string;
} & MarginProps &
  ImageProps &
  PaddingProps;

export interface SimplePageComponentProps extends ReduxSelectorProps {
  baseService: AxiosInstance;
}
export const SimplePageHeader = ({
  useAppSelector,
  baseService,
}: SimplePageComponentProps): ComponentConfig<SimplePageHeaderProps> => {
  return {
    label: (
      <CustomTooltip title="SimplePageHeader">
        <span>Simple Page Header</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      imageFile: { ...imageField(true, baseService).file },
      backgroundImageFile: { ...imageField(false, baseService).file },

      showHeader: {
        type: 'radio',
        label: 'Show Header',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      show: {
        type: 'radio',
        label: 'Show',
        options: [
          { label: 'Cart', value: 'cart' },
          { label: 'Secure Connection', value: 'secure' },
          { label: 'Off', value: '' },
        ],
      },
      CustomizeFonts: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              showHover={true}
              title="Header Text"
            />
          );
        },
      },
      background: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => {
          const [isOpen, setIsOpen] = useState(false);
          const toggleAccordion = () => {
            setIsOpen(!isOpen);
          };
          return (
            <>
              <div
                className="flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
                onClick={toggleAccordion}>
                <h3>Customize Bg Color</h3>
                {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
              {isOpen && (
                <div className="flex flex-col gap-1  mt-3 items-center">
                  <div className="flex flex-col gap-4 w-full items-center border rounded p-2 pb-4">
                    <ColorSelector
                      value={value?.backgroundColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          backgroundColor: val,
                        });
                      }}
                      label="Background color"
                      tooltip="backgroundColor"
                    />
                    <ColorSelector
                      value={value?.hoverColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          hoverColor: val,
                        });
                      }}
                      label="Hover color"
                      tooltip="hoverColor"
                    />
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      ...marginFields,
      ...paddingFields,
      ...imageProps([]),
      logoAlign: {
        type: 'radio',
        label: 'Logo Align',
        options: [
          { label: 'Left', value: true },
          { label: 'Center', value: false },
        ],
      },
      absolute: {
        type: 'radio',
        label: 'Absolute',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
    },
    defaultProps: {
      background: {
        backgroundColor: '#f9f9f9',
        hoverColor: '#f9f9f9',
      },
      backgroundImageFile: {
        Version: 0,
        StoreID: '',
        Filename: 'background',
        BlobURL: '',
      },
      CustomizeFonts: {
        fontSize: '24',
        fontWeight: 'font-bold',
        textColor: '#000000 ',
        hoverColor: '#000000',
        text: 'My Store',
      },
      showHeader: true,
      imageFile: {
        Version: 0,
        StoreID: '',
        Filename: 'logo',
        BlobURL: '',
      },
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-4',
          bottom: 'pb-4',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-4',
          bottom: 'max-md:pb-4',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-2',
          bottom: 'max-sm:pb-2',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      image: {
        imageType: 'inline',
        backgroundPosition: 'bg-center',
        backgroundRepeat: 'bg-no-repeat',
        backgroundSize: 'bg-cover',
        borderRadius: 'rounded-md',
        border: 'border-none',
        maxWidth: 'w-auto',
        height: 'auto',
      },
      absolute: false,
      logoAlign: true,
      show: 'cart',
    },
    render: ({
      imageFile,
      backgroundImageFile,
      margin,
      image,
      background,
      logoAlign,
      absolute,
      padding,
      CustomizeFonts,
      showHeader,
      show,
    }) => {
      const { pathname } = useLocation();
      const isEditModeOn = pathname.includes(Path.PAGE_BUILDER_EDIT);
      const storeData = useAppSelector(state => state.store.storeData);
      const handleClick = () => {
        if (storeData?.ShopURL) {
          window.location.href = storeData?.ShopURL;
        }
      };

      return (
        <>
          <div
            className={`flex justify-between items-center w-full standardLayoutPadding
            max-[760px]:!bg-none ${absolute ? 'absolute' : ''} md:relative top-0 left-0 right-0 z-10
            ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
            ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}
            ${CustomizeFonts?.fontSize} ${CustomizeFonts?.fontWeight}
            ${image?.backgroundPosition}
            ${image?.backgroundRepeat}
            ${image?.backgroundSize}
            ${image?.border}
            ${image?.maxWidth}
            ${image?.borderRadius}
            ${image?.height}
          `}
            style={{
              backgroundImage: `url(${backgroundImageFile?.BlobURL})`,
              backgroundColor: background?.backgroundColor,
            }}
            onMouseOver={e => {
              e.currentTarget.style.backgroundColor = background?.hoverColor;
            }}
            onMouseOut={e => {
              e.currentTarget.style.backgroundColor =
                background?.backgroundColor;
            }}>
            {logoAlign === false && <div></div>}
            <div className="flex cursor-pointer" onClick={handleClick}>
              {imageFile?.BlobURL ? (
                <img
                  className="w-[120px] h-auto"
                  src={imageFile?.BlobURL}
                  alt={imageFile?.Filename}
                />
              ) : isEditModeOn ? (
                <button
                  type="button"
                  className="flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-page_builder_theme">
                  Upload Logo
                  <PlusIcon aria-hidden="true" className="h-5 w-5" />
                </button>
              ) : (
                <button
                  type="button"
                  className="flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                  Logo
                </button>
              )}
            </div>

            {CustomizeFonts?.text && showHeader && (
              <div className="flex items-center justify-center h-full overflow-hidden">
                <p
                  className={` ${CustomizeFonts?.fontSize} ${CustomizeFonts?.fontWeight} ${CustomizeFonts.hoverColor}`}
                  style={{
                    color: `${CustomizeFonts?.textColor}`,
                    fontSize: `${CustomizeFonts?.fontSize}px`,
                  }}
                  onMouseOver={e => {
                    e.currentTarget.style.color = CustomizeFonts?.hoverColor;
                  }}
                  onMouseOut={e => {
                    e.currentTarget.style.color = CustomizeFonts?.textColor
                      ? CustomizeFonts?.textColor
                      : '#000';
                  }}>
                  {CustomizeFonts?.text}
                </p>
              </div>
            )}

            {Boolean(show) && (
              <div className="flex items-center cursor-pointer  h-[26px]">
                {show === 'cart' ? (
                  <div onClick={handleClick}>
                    <CartIcon />
                  </div>
                ) : (
                  show === 'secure' && (
                    <dd className="text-sm font-bold block cursor-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        fill="green"
                        className="h-3 w-3 inline-block align-middle mb-1 mr-1">
                        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                      </svg>
                      SECURE CONNECTION
                    </dd>
                  )
                )}
              </div>
            )}
          </div>
        </>
      );
    },
  };
};
