import { FC } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import FilterForm from 'components/orders/ordersFilter/filterForm';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ReactComponent as ColoumnIcon } from 'assets/icons/columnFilter-icon.svg';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { IOrder, IOrderFormFilter } from 'interface/orderInterface';
import { ActionFilter } from 'components/orders/ordersFilter/actionFilter';
import FilterAction from 'components/common/filterAction';
import { HeadCell } from 'components/orders';
import ColumnFilter from 'components/orders/ordersFilter/columnFilter';
import { setMyColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import ExportFilter from './ExportFilter';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { userPermissionKeys } from 'utils/constants';

interface ISideFilter {
  sideFormFilter: IOrderFormFilter;
  selected: Map<string, IOrder>;
  filterSubmission: (_data: IOrderFormFilter) => void;
  searchOrderList: () => void;
  resetSelected: () => void;
  setHeadCellList: React.Dispatch<React.SetStateAction<HeadCell[]>>;
  headCellList: HeadCell[];
  exportList: () => void;
}

const SideFilter: FC<ISideFilter> = ({
  sideFormFilter,
  filterSubmission,
  setHeadCellList,
  resetSelected,
  selected,
  searchOrderList,
  headCellList,
  exportList,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { order } = useAppSelector(state => state.permission);
  const { orderColumnFilter } = useAppSelector(state => state.coloumnFilter);
  const filterAction = [
    { key: 'Filters', icon: FilterIcon, className: 'margin_filter' },
    { key: 'Actions', icon: ActionIcon, permission: userPermissionKeys.manage },
    { key: 'Column Filters', icon: ColoumnIcon },
    { key: 'Export as', icon: DownloadIcon },
  ];

  const setSelectedFilter = (filter: string) => {
    dispatch(setPageConfig({ filterSource: 'orders', currentFilter: filter }));
  };

  const handleChange = (val: string) => {
    if (orderColumnFilter.indexOf(val) === -1) {
      dispatch(
        setMyColoumnDetails({
          orderColumnFilter: [...orderColumnFilter, val],
        }),
      );
    } else {
      dispatch(
        setMyColoumnDetails({
          orderColumnFilter: [
            ...orderColumnFilter.filter(each => each !== val),
          ],
        }),
      );
    }
    setHeadCellList(
      headCellList.map((each: HeadCell) =>
        each.label === val ? { ...each, hide: !each.hide } : each,
      ),
    );
  };

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFormFilter={sideFormFilter}
            filterSubmission={filterSubmission}
            resetSelected={resetSelected}
          />
        );
      case 'Actions':
        return (
          <ActionFilter
            selected={selected}
            filterList={['Tag']}
            searchOrderList={searchOrderList}
            resetSelected={resetSelected}
          />
        );
      case 'Column Filters':
        return (
          <ColumnFilter
            filterList={headCellList}
            setSelectedColumns={handleChange}
          />
        );
      case 'Export as':
        return <ExportFilter exportList={exportList} />;
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={order}
        setSelectedFilter={setSelectedFilter}
      />
      {!!currentFilter && filterSource === 'orders' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
